import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import Notifications, { notify } from "react-notify-toast"
import axios from "axios"

import "../styles/contacto.scss"

import LogoInstagram from "../images/logo_instagram.png"
import LogoFacebook from "../images/logo_facebook.png"

export default function Contacto() {
  function getCookie(cname) {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  const [state, setState] = useState({
    namekujas: "",
    phone: "",
    email: "",
    question: "",
    comments: "",
  })

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const submitLeadFrom = async () => {
    await axios.post("https://www.sico.com.co/SicoApi/api/Mercadeo/LeadAddSICO", {
      "strToken": "ABE2E037B2CA42299343DBE712BA6BBC",
      "email": state.email,
      "name": state.namekujas,
      "origin": window.location.href,
      "medio": "Landing Page Grupo Ínsula",
      "phone": state.phone,
      "message": `Consulta: ${state.question} \n Comentario: ${state.comments}`,
    },
    {
      auth: {
        username: "apiUserJUANAMBU",
        password: "V7G5W2A4E8-D-VJZ"
      }
    });
  }

  const handleSubmit = async e => {
    e.preventDefault()

    //   const uri =
    //     "https://app-3QNMYMXMQA.marketingautomation.services/webforms/receivePostback/MzawMLEwMjcxBwA/a8608faa-9dbd-496d-80ad-67deb2c52c6f/jsonp/?"

    //   var params = "firstName=" + encodeURI(state.namekujas) + "&"
    //   params = params + "phone=" + encodeURI(state.phone) + "&"
    //   params = params + "email=" + encodeURI(state.email) + "&"
    //   params = params + "campaign=" + encodeURI("Pagina Web") + "&"
    //   params = params + "trackingid__sb=" + encodeURI(getCookie("__ss_tk"))

    //   const url = uri + params

    try {
      // const res = await fetch(url, { mode: "no-cors" })
      // console.log(res.ok)
      await submitLeadFrom();

      await firebase
        .firestore()
        .collection("website")
        .doc()
        .set({ ...state, createdAt: Date.now() })

      notify.show(
        "Hemos recibido tu registro exitosamente. Pronto nos comunicaremos contigo",
        "success",
        5000
      )

      // await db.collection('grupo-insula').doc().set(state)
      setState({
        name: "",
        phone: "",
        email: "",
        question: "",
        comments: "",
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Notifications options={{ top: "90%" }} />
      <div className="contacto__title" id="contacto">
        <div className="contacto__texture" />
        <h1>CONTÁCTANOS</h1>
      </div>
      <div className="contacto__header">
        <div className="left">
          <h2>¿Quieres saber más de Grupo Ínsula?</h2>
          <p>
            Déjanos tus datos y tu consulta y contestaremos en la mayor brevedad
            posible.
          </p>
        </div>
        <div className="right">
          <a
            href="https://www.instagram.com/grupoinsula/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LogoInstagram} alt="Instragram Grupo Insula" />
          </a>

          <a
            href="https://www.facebook.com/insulagrupo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LogoFacebook} alt="Facebook Grupo Insula" />
          </a>
        </div>
      </div>
      <div className="contacto__formulario">
        <div name="contact" className="inner-container">
          <form onSubmit={handleSubmit} id="formulario-insula">
            <div className="input-group">
              <input
                type="text"
                name="namekujas"
                placeholder="NOMBRE"
                required
                onChange={handleChange}
                value={state.name}
              />

              <input
                type="text"
                name="phone"
                placeholder="CELULAR"
                required
                onChange={handleChange}
                value={state.phone}
              />
            </div>
            <div className="input-group">
              <input
                type="email"
                placeholder="CORREO"
                name="email"
                required
                onChange={handleChange}
                value={state.email}
              />
              <input
                type="text"
                placeholder="CONSULTA"
                name="question"
                required
                value={state.question}
                onChange={handleChange}
              />
            </div>
            <textarea
              placeholder="COMENTARIOS (OPCIONAL)"
              rows="6"
              name="comments"
              onChange={handleChange}
            />
            <input
              type="hidden"
              name="bot-field"
              value="contact"
              onChange={handleChange}
              value={state.comments}
            />
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}
